<template>
  <div class="Certifications">
    <v-container
      :class="{
        'div-certifications-2': $route.name === 'Our Story',
        'div-certifications': $route.name !== 'Our Story',
      }"
      :fluid="$vuetify.breakpoint.mdAndDown || $vuetify.breakpoint.width > 2299 ? true : false"
    >
      <v-row class="my-12" style="width: 100%">
        <v-col class="text-center">
          <h1 class="pinyon title-app-our-certifications">Our certifications</h1>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="margin-custom-16581">
        <v-col cols="12" lg="12" xl="11">
          <v-row justify="center" align="center">
            <v-col
              cols="auto"
              sm="3"
              md="auto"
              class="px-8 px-sm-6 mx-sm-4 mx-xl-10"
              v-bind:class="{'margin-x-2k': $vuetify.breakpoint.width > 2299}"
              v-for="(certificate, c) in certifications"
              v-bind:key="c"
            >
              <template v-if="certificate.cover !== null">
                <v-img
                  :src="
                    'https://www.lakewaycms.actstudio.xyz' +
                    certificate.cover.path
                  "
                  contain
                  eager
                  class="logo-certifcate"
                ></v-img>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Certifications",
  data() {
    return {
      loaded: false,
      certifications: [],
    };
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }

    var res = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/collections/get/certifications?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    ).then((data) => data.json());
    this.certifications = res.entries;
  },
};
</script>
<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.div-certifications {
  padding: 183px 0 !important;
  @media screen and(max-width: $xxl) {
    padding: 130px 0 !important;
  }
  @media screen and(max-width: $xl) {
    padding: 70px 0 !important;
  }

  @media screen and(max-width: $lg) {
    padding: 100px 0 !important;
  }
}

.div-certifications-2 {
  padding-top: 40px !important;
  padding-bottom: 100px !important;

  @media screen and(max-width: $lg) {
    padding: 100px 0 !important;
    padding-top: 50px !important;
  }
  @media screen and(max-width: $sm) {
    padding: 100px 0 !important;
    padding-top: 10px !important;
  }
}
.margin-custom-16581 {
  margin: 93px 0px 48px 0px !important;
  
  @media screen and(max-width: $xxl) {
    margin: 78px 0px 48px 0px !important;
  }

  @media screen and(max-width: $sm) {
    margin: 50px 0px 48px 0px !important;
  }
}

.logo-certifcate {
  max-width: 258px;
  @media screen and(max-width: $xxl) {
    max-height: 350px;
    max-width: 180px;
  }

  @media screen and(max-width: $xl) {
    max-width: 130px;
    max-height: 350px;
  }

  @media screen and(max-width: $lg) {
    max-width: 125px;
    max-height: 350px;
  }

  @media screen and(max-width: $sm) {
    margin: auto;
    max-width: 100px;
    max-height: 350px;
  }
}
.title-app-our-certifications{
     font-size: 130px;
   @media screen and(max-width: $xxl) {
     font-size: 98px;
   }
   @media screen and(max-width: $xl) {
    font-size: 60px;
  }

  @media screen and(max-width: $md) {
    font-size: 52px;
  }
  @media screen and(max-width: $sm) {
    font-size: 38px;
  }
}

.margin-x-2k{
  @media screen and(min-width: $xxl) {
    margin-right:48px !important;
    margin-left:48px !important;
  }
}
</style>