<template>
  <div v-if="storySingleton.show_section">
    <v-container
      class="pt-7 pt-md-12 mt-1 mt-xl-12 mb-1 mb-xl-12 pb-7 pb-sm-12"
      :fluid="$vuetify.breakpoint.lgAndUp ? false : true"
    >
      <v-row align="center" justify="center">
        <v-col cols="auto" class="px-xl-6" order="1" order-md="0">
          <div class="text-second-block">
            <span
              class="semibold subtitles-app-new"
              v-html="storySingleton.title"
            ></span>
            <p
              v-html="storySingleton.block_1_card1.text"
              style="white-space: pre-line"
              class="margin-top-16851 text-left"
            ></p>

            <v-img
              v-if="storySingleton.block_1_card1.img !== null"
              v-bind:src="
                'https://www.lakewaycms.actstudio.xyz' +
                storySingleton.block_1_card1.img.path
              "
              class="mt-6"
              eager
            >
            </v-img>
          </div>
        </v-col>
        <v-col cols="auto" class="px-xl-6" order="0" order-md="1">
          <div
            class="img-our-story-1 position-relative"
            @mouseover="showNavigation = true"
            @mouseleave="showNavigation = false"
          >
            <template v-if="showNavigation">
              <div class="arrow-prev">
                <v-icon
                  class="white--text"
                  @click="$refs.carouselGallery.slidePrev()"
                  >mdi-chevron-left</v-icon
                >
              </div>
              <div class="arrow-next">
                <v-icon
                  class="white--text"
                  @click="$refs.carouselGallery.slideNext()"
                  >mdi-chevron-right</v-icon
                >
              </div>
            </template>

            <hooper :settings="hooperSettings" ref="carouselGallery">
              <slide
                v-for="(image, i) in storySingleton.block_1_gallery"
                v-bind:key="i"
              >
                <v-img
                  v-if="image !== null"
                  v-bind:src="
                    'https://www.lakewaycms.actstudio.xyz' + image.path
                  "
                  class="mb-6 img-our-story-1"
                  eager
                ></v-img>
              </slide>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>

            <!-- <v-img
              v-if="storySingleton.block_1_card2.img !== null"
              v-bind:src="
                'https://www.lakewaycms.actstudio.xyz' +
                storySingleton.block_1_card2.img.path
              "
              class="mb-6 img-our-story-1"
              eager
            ></v-img> -->
            <p
              v-html="storySingleton.block_1_card2.text"
              style="white-space: pre-line"
              class=""
            ></p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="mt-5 mt-md-12">
      <v-row class="my-6">
        <v-col class="px-0">
          <template v-if="storySingleton.block_2_img !== null">
            <template v-if="storySingleton.block_2_img.image">
              <v-img
                v-bind:src="
                  'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
                  storySingleton.block_2_img.path
                "
                eager
                class="img-banner-our-story"
              ></v-img>
            </template>
            <template v-else-if="storySingleton.block_2_img.video">
              <video
                v-bind:src="
                  'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
                  storySingleton.block_2_img.path
                "
                style="object-fit: cover"
                class="img-banner-our-story"
              ></video>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      class="mt-sm-5"
      :fluid="$vuetify.breakpoint.lgAndDown ? true : false"
    >
      <v-row class="my-0 my-sm-6" justify="center">
        <v-col cols="auto" sm="12" md="auto" class="column-left-1635">
          <div class="mx-auto text-fourth-block">
            <span
              class="subtitles-app-new semibold"
              v-html="storySingleton.block_3_title"
            >
            </span>
          </div>
        </v-col>
        <v-col cols="auto" sm="12" md="auto" class="px-xl-6">
          <div class="mx-auto text-fourth-block-2">
            <p
              v-html="storySingleton.block_3_text"
              style="white-space: pre-line"
            ></p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <Certifications></Certifications>
  </div>
</template>

<script>
import Certifications from "@/components/global/Certifications.vue";
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "OurStory",
  components: {
    Certifications,
    Hooper,
    Slide,
    HooperPagination,
  },
  data() {
    return {
      storySingleton: {
        block_1_card1: {
          img: {
            path: "",
          },
        },
        block_1_card2: {
          img: {
            path: "",
          },
        },
        block_2_img: {
          path: "",
        },
      },
      hooperSettings: {
        itemsToShow: 1,
        infiniteScroll: true,
        mouseDrag: false,
        wheelControl: false,
        centerMode: true,
        transition: 0,
        autoPlay: true,
        playSpeed: 4000,
      },
      showNavigation: false,
    };
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }
    this.storySingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/story?q=" + key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.storySingleton = data.content));
  },
};
</script>

<style lang="scss" scoped>
$xxl: 3629px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.title-intro {
  max-width: 1072px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}

.img-our-story-1 {
  width: 500px;
  height: 650px;

  @media screen and(max-width: $xl) {
    width: 350px;
    height: 500px;
  }

  @media screen and(max-width: $md) {
    width: 500px;
    height: 600px;
  }

  @media screen and(max-width: $sm) {
    width: 300px;
    height: 400px;
  }
}

.text-second-block {
  max-width: 700px;
  margin-right: 129px;

  @media screen and(max-width: $xl) {
    max-width: 481px;
    margin-right: 83px;
  }

  @media screen and(max-width: $md) {
    max-width: 600px;
    margin-right: 0px;
    margin-top: 20px;
    text-align: center;
  }

  @media screen and(max-width: $sm) {
    max-width: 300px;
    margin-right: 0px;
    margin-top: 50px;
  }
}

.column-left {
  margin-right: 189px;
}

.img-banner-our-story {
  height: 650px;

  @media screen and(max-width: $xl) {
    height: 450px;
  }
}

.column-left-1635 {
  margin-right: 97px;

  @media screen and(max-width: $xl) {
    margin-right: 48px;
  }

  @media screen and(max-width: $md) {
    margin-right: 0px;
  }
}

.text-fourth-block {
  max-width: 542px;

  @media screen and(max-width: $xl) {
    max-width: 410px;
  }

  @media screen and(max-width: $md) {
    max-width: fit-content;
    text-align: center;
  }
  @media screen and(max-width: $sm) {
    max-width: 300px;
    margin-right: 0px;
  }
}

.text-fourth-block-2 {
  max-width: 542px;
  @media screen and(max-width: $xl) {
    max-width: 442px;
  }
  @media screen and(max-width: $md) {
    max-width: 500px;
    margin-top: 15px;
  }
  @media screen and(max-width: $sm) {
    max-width: 300px;
    margin-right: 0px;
    margin-top: 0px;
  }
}

.margin-top-16851 {
  margin-top: 60px;
}
</style>